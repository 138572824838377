import * as THREE from 'three';
import IMOG from '~/lib/imog';

import BoxMeshStandardMaterial from './BoxMeshStandardMaterial';
import BoxMeshDepthMaterial from './BoxMeshDepthMaterial';

const g = new THREE.BoxBufferGeometry(1, 1, 1, 100, 100, 100);

export default IMOG.Component('Box', {
  options: {
    addTo: null,
  },

  props() {
    return {
      active: false,
      size: 1,
      dissolveCenter: [0, 0, 0],
      dissolveRadius: 0.6,
      dissolveFallof: 0.22,
      dissolveColor: '#ffbb87',
      time: 0,
    };
  },

  async setup({ options }) {
    this.materialOutside = new BoxMeshStandardMaterial({
      color: 0x666666,
    });
    this.meshOutside = new THREE.Mesh(g, this.materialOutside);
    this.meshOutside.customDepthMaterial = new BoxMeshDepthMaterial({
      depthPacking: THREE.RGBADepthPacking,
    });
    this.meshOutside.position.y = 0.55;
    this.meshOutside.castShadow = true;
    this.meshOutside.renderOrder = 2;
    if (options.addTo) options.addTo.add(this.meshOutside);

    this.materialInside = new BoxMeshStandardMaterial({
      color: 0x111111,
      side: THREE.BackSide,
    });
    this.meshInside = new THREE.Mesh(g, this.materialInside);
    this.meshInside.customDepthMaterial = new BoxMeshDepthMaterial({
      depthPacking: THREE.RGBADepthPacking,
      side: THREE.BackSide,
    });

    this.meshInside.position.y = 0.55;
    // this.meshInside.castShadow = true;
    if (options.addTo) options.addTo.add(this.meshInside);

    this.materials = [
      this.materialOutside,
      this.meshOutside.customDepthMaterial,
      this.materialInside,
    ];

    if (this.$gui) {
      const f = this.$gui.addFolder({
        title: 'Dissolve',
        expanded: false,
      });
      f.addInput(this.props, 'dissolveFallof', {
        min: 0.01,
        max: 0.5,
        step: 0.001,
      });
      f.addInput(this.props, 'dissolveColor', {});
    }

    this.props.active = true;
  },

  hooks: {
    'while:active'() {
      this.props.time += 0.001;
    },
    'set:size'(v) {
      this.meshOutside.scale.setScalar(v);
      this.meshInside.scale.setScalar(v);
    },
    'set:dissolveCenter'(v) {
      this.materials.forEach((mat) =>
        mat.uniforms.uDissolveC.value.fromArray(v)
      );
    },
    'set:dissolveRadius'(v) {
      this.materials.forEach((mat) => {
        mat.uniforms.uDissolveR.value = v;
      });
    },
    'set:dissolveFallof'(v) {
      this.materials.forEach((mat) => {
        mat.uniforms.uDissolveF.value = v;
      });
    },
    'set:dissolveColor'(v) {
      this.materialOutside.uniforms.uDissolveColor.value.setStyle(v);
      this.materialInside.uniforms.uDissolveColor.value.setStyle(v);
    },
    'set:time'(v) {
      this.materials.forEach((mat) => {
        mat.uniforms.uTime.value = v;
      });
    },
  },
});
