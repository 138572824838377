import Canvas from '~/component/Canvas/index';

window.canvas = new Canvas();

const marq = document.querySelector('.scfex-main-panel__marq');
const inner = marq.firstElementChild;
let x = 0;
const tick = () => {
  x -= 0.2;
  if (x < -42) x = 0;
  inner.style.transform = `translateX(${x}px)`;
  requestAnimationFrame(tick);
};
tick();
