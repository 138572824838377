import * as THREE from 'three';
import IMOG from '~/lib/imog';
import { pnoise, fbm } from '~/lib/glsl/noise';

const loader = new THREE.TextureLoader();

export default class BoxMeshStandardMaterial extends THREE.MeshStandardMaterial {
  constructor(options) {
    super({ transparent: true, ...options });

    this.uniforms = {
      uDissolveC: { value: new THREE.Vector3() },
      uDissolveR: { value: 0.6 },
      uDissolveF: { value: 0.1 },
      uDissolveColor: { value: new THREE.Color('red') },
      uTime: { value: 0 },
      ...THREE.ShaderLib.standard.uniforms,
    };
    this.isMeshStandardMaterial = true;
    this.type = 'BoxMeshStandardMaterial';

    this.vertexShader = `
    #define STANDARD
    varying vec3 vViewPosition;
  #include <common>
  #include <uv_pars_vertex>
  #include <uv2_pars_vertex>
  #include <displacementmap_pars_vertex>
  #include <color_pars_vertex>
  #include <fog_pars_vertex>
  #include <normal_pars_vertex>
  #include <morphtarget_pars_vertex>
  #include <skinning_pars_vertex>
  #include <shadowmap_pars_vertex>
  #include <logdepthbuf_pars_vertex>
  #include <clipping_planes_pars_vertex>

  varying float vGrad;
  varying vec2 vUv;
  // varying vec3 vT;

  uniform vec3 uDissolveC;
  uniform float uDissolveR;
  uniform float uDissolveF;
  uniform float uTime;

  ${pnoise}

  void main() {
  	#include <uv_vertex>
  	#include <uv2_vertex>
  	#include <color_vertex>
  	#include <beginnormal_vertex>
  	#include <morphnormal_vertex>
  	#include <skinbase_vertex>
  	#include <skinnormal_vertex>
  	#include <defaultnormal_vertex>
  	#include <normal_vertex>
  	#include <begin_vertex>

    vec3 vvWorldPosition = (modelMatrix * vec4(transformed, 1.0)).xyz;
    vec3 n = vec3(
      pnoise((vvWorldPosition + uTime) * 5.0, vec3(10.0)),
      pnoise((vvWorldPosition + 0.32 - uTime) * 5.0, vec3(10.0)),
      pnoise((vvWorldPosition + 0.746 + uTime) * 5.0, vec3(10.0))
    );
    vGrad = length(vvWorldPosition - uDissolveC + n * 0.1);
    float fallof = pow(smoothstep(uDissolveR + uDissolveF * 2.0, uDissolveR, vGrad), 2.0);
    transformed +=  0.15 * n * fallof;// + 0.1 * normal * fallof;
    transformed.y = clamp(transformed.y, -0.5, 999999.99);


  	#include <morphtarget_vertex>
  	#include <skinning_vertex>
  	#include <displacementmap_vertex>
  	#include <project_vertex>
  	#include <logdepthbuf_vertex>
  	#include <clipping_planes_vertex>
  	vViewPosition = - mvPosition.xyz;
  	#include <worldpos_vertex>
  	#include <shadowmap_vertex>
  	#include <fog_vertex>

    // gl_Position.xyz += 0.15 * n * fallof;

    // vT = n * fallof;
    vUv = uv.xy;
  }
    `;
    this.fragmentShader = `
  #define STANDARD
  #ifdef PHYSICAL
  	#define IOR
  	#define SPECULAR
  #endif
  uniform vec3 diffuse;
  uniform vec3 emissive;
  uniform float roughness;
  uniform float metalness;
  uniform float opacity;
  #ifdef IOR
  	uniform float ior;
  #endif
  #ifdef SPECULAR
  	uniform float specularIntensity;
  	uniform vec3 specularTint;
  	#ifdef USE_SPECULARINTENSITYMAP
  		uniform sampler2D specularIntensityMap;
  	#endif
  	#ifdef USE_SPECULARTINTMAP
  		uniform sampler2D specularTintMap;
  	#endif
  #endif
  #ifdef USE_CLEARCOAT
  	uniform float clearcoat;
  	uniform float clearcoatRoughness;
  #endif
  #ifdef USE_SHEEN
  	uniform vec3 sheenTint;
  	uniform float sheenRoughness;
  #endif
  varying vec3 vViewPosition;
  #include <common>
  #include <packing>
  #include <dithering_pars_fragment>
  #include <color_pars_fragment>
  #include <uv_pars_fragment>
  #include <uv2_pars_fragment>
  #include <map_pars_fragment>
  #include <alphamap_pars_fragment>
  #include <alphatest_pars_fragment>
  #include <aomap_pars_fragment>
  #include <lightmap_pars_fragment>
  #include <emissivemap_pars_fragment>
  #include <bsdfs>
  #include <cube_uv_reflection_fragment>
  #include <envmap_common_pars_fragment>
  #include <envmap_physical_pars_fragment>
  #include <fog_pars_fragment>
  #include <lights_pars_begin>
  #include <normal_pars_fragment>
  #include <lights_physical_pars_fragment>
  #include <transmission_pars_fragment>
  #include <shadowmap_pars_fragment>
  #include <bumpmap_pars_fragment>
  #include <normalmap_pars_fragment>
  #include <clearcoat_pars_fragment>
  #include <roughnessmap_pars_fragment>
  #include <metalnessmap_pars_fragment>
  #include <logdepthbuf_pars_fragment>
  #include <clipping_planes_pars_fragment>

  // varying vec3 vvWorldPosition;
  varying float vGrad;
  // varying vec3 vT;
  varying vec2 vUv;
  uniform float uDissolveR;
  uniform float uDissolveF;
  uniform vec3 uDissolveColor;
  uniform float uTime;

  ${fbm}

  void main() {
    // vec3 dSTdx = dFdx( vViewPosition );
    // vec3 dSTdy = dFdy( vViewPosition );

  	#include <clipping_planes_fragment>
  	vec4 diffuseColor = vec4( diffuse, opacity );
  	ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
  	vec3 totalEmissiveRadiance = emissive;
  	#include <logdepthbuf_fragment>
  	#include <map_fragment>
  	#include <color_fragment>
  	#include <alphamap_fragment>
  	#include <alphatest_fragment>
  	#include <roughnessmap_fragment>
  	#include <metalnessmap_fragment>
  	#include <normal_fragment_begin>
  	#include <normal_fragment_maps>
    normal  = normalize(cross(dFdx(vViewPosition), dFdy(vViewPosition)));
  	#include <clearcoat_normal_fragment_begin>
  	#include <clearcoat_normal_fragment_maps>
  	#include <emissivemap_fragment>
  	// accumulation
  	#include <lights_physical_fragment>
  	#include <lights_fragment_begin>
  	#include <lights_fragment_maps>
  	#include <lights_fragment_end>
  	// modulation
  	#include <aomap_fragment>
  	vec3 totalDiffuse = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse;
  	vec3 totalSpecular = reflectedLight.directSpecular + reflectedLight.indirectSpecular;
  	#include <transmission_fragment>
  	vec3 outgoingLight = totalDiffuse + totalSpecular + totalEmissiveRadiance;
  	#ifdef USE_CLEARCOAT
  		float dotNVcc = saturate( dot( geometry.clearcoatNormal, geometry.viewDir ) );
  		vec3 Fcc = F_Schlick( material.clearcoatF0, material.clearcoatF90, dotNVcc );
  		outgoingLight = outgoingLight * ( 1.0 - clearcoat * Fcc ) + clearcoatSpecular * clearcoat;
  	#endif
  	#include <output_fragment>
  	#include <tonemapping_fragment>
  	#include <encodings_fragment>
  	#include <fog_fragment>
  	#include <premultiplied_alpha_fragment>
  	#include <dithering_fragment>

    // gl_FragColor = vec4(vvWorldPosition, 1.0);
    float flameV = pow(animateFbm(vUv * 5.0, uTime * 20.0), 2.0);
    vec3 flameC = vec3(0.985,0.020,0.152) * 0.6 + flameV * vec3(1.000,0.954,0.001) * 3.0;

    float light = smoothstep(uDissolveR + uDissolveF * 0.75, uDissolveR + uDissolveF * 0.75 - 0.01, vGrad + noise(vUv * 10.0) * 0.15 - flameV * 0.1);
    float shadow = smoothstep(uDissolveR + uDissolveF + 0.05, uDissolveR, vGrad);
    gl_FragColor.rgb = mix(gl_FragColor.rgb, flameC, light);
    gl_FragColor.rgb -= 0.2 * (shadow - light);
    gl_FragColor.a = step(uDissolveR, vGrad - flameV * 0.1);
    // gl_FragColor.rgb = flameC;
    if (gl_FragColor.a < 0.5) discard;
  }
    `;
  }
}
