import * as THREE from 'three';
export default class CircleMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      vertexShader: /*glsl*/ `
        varying vec2 vUv;

        void main() {
            vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
            gl_Position = projectionMatrix * mvPosition;
            vUv = uv;
        }`,

      fragmentShader: /*glsl*/ `
        varying vec2 vUv;
        uniform float uStroke;

        float sdCircle( vec2 p, float r ) {
            return length(p) - r;
        }

        float opOnion( in float sdf, in float thickness ) {
            return abs(sdf)-thickness;
        }

        void main() {
          vec3 color = vec3(0.922,0.369,0.);
          float a = opOnion(sdCircle(vUv - 0.5, 0.4), uStroke);
          a = 1.0 - sign(a);
          gl_FragColor = vec4(color, a);
        }`,
      uniforms: {
        uStroke: { value: 0.015 },
      },
      transparent: true,
      toneMapped: false,
      depthTest: false,
    });
  }
}
