import * as THREE from 'three';

const loader = new THREE.TextureLoader();
const loadTexture = (url) =>
  new Promise((resolve, reject) => {
    loader.load(
      url,
      (texture) => resolve(texture),
      () => {},
      () => reject()
    );
  });

export default loadTexture;
