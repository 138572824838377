import IMOG from '~/lib/imog';
// import gui from '~/lib/gui';
import Stats from '~/lib/stats';
const stats = new Stats();
document.body.appendChild(stats.dom);
requestAnimationFrame(function loop() {
  stats.update();
  requestAnimationFrame(loop);
});

import Renderer from '~/component/Renderer/index';
import Scene from '~/component/Scene/index';

export default IMOG.Component('Canvas', {
  setup() {
    this.renderer = new Renderer();

    this.scene = new Scene({
      options: {
        addTo: this.renderer.worldScene,
      },
    });
  },

  render() {
    this.renderer.render();
  },
});
