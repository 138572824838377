import * as THREE from 'three';
import IMOG from '~/lib/imog';

const loader = new THREE.TextureLoader();

export default class FloorMeshStandardMaterial extends THREE.MeshStandardMaterial {
  constructor(options) {
    super(options);

    this.uniforms = {
      ...THREE.ShaderLib.standard.uniforms,
    };
    this.isMeshStandardMaterial = true;
    this.type = 'FloorMeshStandardMaterial';

    this.vertexShader = `
    #define STANDARD
    varying vec3 vViewPosition;
  	// varying vec3 vWorldPosition;
  #include <common>
  #include <uv_pars_vertex>
  #include <uv2_pars_vertex>
  #include <displacementmap_pars_vertex>
  #include <color_pars_vertex>
  #include <fog_pars_vertex>
  #include <normal_pars_vertex>
  #include <morphtarget_pars_vertex>
  #include <skinning_pars_vertex>
  #include <shadowmap_pars_vertex>
  #include <logdepthbuf_pars_vertex>
  #include <clipping_planes_pars_vertex>
  varying vec3 vvWorldPosition;
  void main() {
  	#include <uv_vertex>
  	#include <uv2_vertex>
  	#include <color_vertex>
  	#include <beginnormal_vertex>
  	#include <morphnormal_vertex>
  	#include <skinbase_vertex>
  	#include <skinnormal_vertex>
  	#include <defaultnormal_vertex>
  	#include <normal_vertex>
  	#include <begin_vertex>
  	#include <morphtarget_vertex>
  	#include <skinning_vertex>
  	#include <displacementmap_vertex>
  	#include <project_vertex>
  	#include <logdepthbuf_vertex>
  	#include <clipping_planes_vertex>
  	vViewPosition = - mvPosition.xyz;
  	#include <worldpos_vertex>
  	#include <shadowmap_vertex>
  	#include <fog_vertex>
    vvWorldPosition = (modelMatrix * vec4(transformed, 1.0)).xyz;
  }
    `;
    this.fragmentShader = `
  #define STANDARD
  #ifdef PHYSICAL
  	#define IOR
  	#define SPECULAR
  #endif
  uniform vec3 diffuse;
  uniform vec3 emissive;
  uniform float roughness;
  uniform float metalness;
  uniform float opacity;
  #ifdef IOR
  	uniform float ior;
  #endif
  #ifdef SPECULAR
  	uniform float specularIntensity;
  	uniform vec3 specularTint;
  	#ifdef USE_SPECULARINTENSITYMAP
  		uniform sampler2D specularIntensityMap;
  	#endif
  	#ifdef USE_SPECULARTINTMAP
  		uniform sampler2D specularTintMap;
  	#endif
  #endif
  #ifdef USE_CLEARCOAT
  	uniform float clearcoat;
  	uniform float clearcoatRoughness;
  #endif
  #ifdef USE_SHEEN
  	uniform vec3 sheenTint;
  	uniform float sheenRoughness;
  #endif
  varying vec3 vViewPosition;
  varying vec3 vWorldPosition;
  #include <common>
  #include <packing>
  #include <dithering_pars_fragment>
  #include <color_pars_fragment>
  #include <uv_pars_fragment>
  #include <uv2_pars_fragment>
  #include <map_pars_fragment>
  #include <alphamap_pars_fragment>
  #include <alphatest_pars_fragment>
  #include <aomap_pars_fragment>
  #include <lightmap_pars_fragment>
  #include <emissivemap_pars_fragment>
  #include <bsdfs>
  #include <cube_uv_reflection_fragment>
  #include <envmap_common_pars_fragment>
  #include <envmap_physical_pars_fragment>
  #include <fog_pars_fragment>
  #include <lights_pars_begin>
  #include <normal_pars_fragment>
  #include <lights_physical_pars_fragment>
  #include <transmission_pars_fragment>
  #include <shadowmap_pars_fragment>
  #include <bumpmap_pars_fragment>
  #include <normalmap_pars_fragment>
  #include <clearcoat_pars_fragment>
  #include <roughnessmap_pars_fragment>
  #include <metalnessmap_pars_fragment>
  #include <logdepthbuf_pars_fragment>
  #include <clipping_planes_pars_fragment>

  varying vec3 vvWorldPosition;
  float checker(vec2 uv, float repeats){
    float cx = floor(repeats * uv.x);
    float cy = floor(repeats * uv.y);
    float result = mod(cx + cy, 2.0);
    return clamp(sign(result), 0.0, 1.0);
  }

  void main() {
  	#include <clipping_planes_fragment>
  	vec4 diffuseColor = vec4( diffuse, opacity );
  	ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
  	vec3 totalEmissiveRadiance = emissive;
  	#include <logdepthbuf_fragment>
  	#include <map_fragment>
  	#include <color_fragment>
  	#include <alphamap_fragment>
  	#include <alphatest_fragment>
  	#include <roughnessmap_fragment>
  	#include <metalnessmap_fragment>
  	#include <normal_fragment_begin>
  	#include <normal_fragment_maps>
  	#include <clearcoat_normal_fragment_begin>
  	#include <clearcoat_normal_fragment_maps>
  	#include <emissivemap_fragment>
  	// accumulation
  	#include <lights_physical_fragment>
  	#include <lights_fragment_begin>
  	#include <lights_fragment_maps>
  	#include <lights_fragment_end>
  	// modulation
  	#include <aomap_fragment>
  	vec3 totalDiffuse = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse;
  	vec3 totalSpecular = reflectedLight.directSpecular + reflectedLight.indirectSpecular;
  	#include <transmission_fragment>
  	vec3 outgoingLight = totalDiffuse + totalSpecular + totalEmissiveRadiance;
  	#ifdef USE_CLEARCOAT
  		float dotNVcc = saturate( dot( geometry.clearcoatNormal, geometry.viewDir ) );
  		vec3 Fcc = F_Schlick( material.clearcoatF0, material.clearcoatF90, dotNVcc );
  		outgoingLight = outgoingLight * ( 1.0 - clearcoat * Fcc ) + clearcoatSpecular * clearcoat;
  	#endif
  	#include <output_fragment>
  	#include <tonemapping_fragment>
  	#include <encodings_fragment>
  	#include <fog_fragment>
  	#include <premultiplied_alpha_fragment>
  	#include <dithering_fragment>

    float checker1V = checker(vvWorldPosition.xz, 1.0);
    float checker2V = checker(vvWorldPosition.xz, 10.0) * mix(-1.0, 1.0, checker1V);
    float checkerV = checker1V * 0.3 + checker2V * 0.2;

    gl_FragColor.rgb *= mix(0.35, 0.5, checkerV);
    // gl_FragColor.rgb = vec3(mix(0.0, 1.2, checkerV));

  }
    `;
  }
}
