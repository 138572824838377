import * as THREE from 'three';
import IMOG from '~/lib/imog';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';
import useWheel from '~/lib/imog/use/wheel';
import { map } from '~/lib/math';

import CircleMaterial from './CircleMaterial';

// raycaster
const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();

// geometry
const gX = new THREE.BoxBufferGeometry(25, 1, 1);
const gY = new THREE.BoxBufferGeometry(1, 25, 1);
const gZ = new THREE.BoxBufferGeometry(1, 1, 25);
const g = BufferGeometryUtils.mergeBufferGeometries([gX, gY, gZ]);
g.scale(0.01, 0.01, 0.01);

// extra
const v = new THREE.Vector3();

export default IMOG.Component('Gizmo', {
  options: {
    addTo: null,
  },

  props() {
    return {
      active: false,
      position: [0, 0, 0],
      wheel: useWheel({ y: 50, min: 0, max: 100, mult: 0.5 }),
      radius: (props) => map(props.wheel.y, 0, 100, 1, 0),
      scale: 1.2,
    };
  },

  async setup({ options }) {
    this.material = new THREE.MeshBasicMaterial({
      color: 'black',
      toneMapped: false,
      depthTest: false,
      transparent: true,
    });
    this.gizmo = new THREE.Mesh(g, this.material);
    this.gizmo.renderOrder = 4;
    if (options.addTo) options.addTo.add(this.gizmo);

    this.circle = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(0.4, 0.4),
      new CircleMaterial()
    );
    this.circle.renderOrder = 3;
    if (options.addTo) options.addTo.add(this.circle);

    this.circleR = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(1, 1),
      new CircleMaterial()
    );
    this.circleR.renderOrder = 3;
    if (options.addTo) options.addTo.add(this.circleR);

    this.plane = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(10, 10),
      new THREE.MeshBasicMaterial({ wireframe: true, visible: false })
    );
    this.plane.position.y = 0.7;
    if (options.addTo) options.addTo.add(this.plane);

    window.addEventListener('mousemove', () => {
      mouse.x = ((event.clientX - 10) / window.innerWidth) * 2 - 1;
      mouse.y = -((event.clientY - 10) / window.innerHeight) * 2 + 1;
    });

    this.props.active = true;
  },

  hooks: {
    'while:active'() {
      raycaster.setFromCamera(mouse, this.$worldCamera);
      const intersects = raycaster.intersectObjects([this.plane]);
      v.copy(this.$worldCamera.position).sub(this.plane.position).setLength(1);
      this.plane.position.set(0, 0, 0).add(v);
      this.plane.lookAt(this.$worldCamera.position);
      if (intersects.length) {
        this.gizmo.visible = true;
        this.gizmo.position.copy(intersects[0].point);
        this.circle.visible = true;
        this.circle.position.copy(intersects[0].point);
        this.circle.lookAt(this.$worldCamera.position);
        this.circleR.position.copy(intersects[0].point);
        this.circleR.lookAt(this.$worldCamera.position);
        this.props.position = intersects[0].point.toArray();
      } else {
        this.gizmo.visible = false;
        this.circle.visible = false;
      }
    },
    'set:scale'(v) {
      this.gizmo.scale.setScalar(v);
      this.circle.scale.setScalar(v);
      this.circleR.scale.setScalar(v);
    },
    'set:radius'(v) {
      this.circleR.scale.setScalar(this.props.scale * map(v, 0, 1, 0.4, 1));
      this.circleR.material.uniforms.uStroke.value = map(
        v,
        0,
        1,
        0.015,
        0.015 * 0.4
      );
    },
  },
});
