import * as THREE from 'three';
import IMOG from '~/lib/imog';
import loadTexture from '~/lib/three/loadTexture';
import { map } from '~/lib/math';

import Gizmo from './Gizmo';
import Sky from './Sky';
import Floor from './Floor';
import Box from './Box';

export default IMOG.Component('Object', {
  options: {
    addTo: null,
  },

  props() {
    return {
      active: false,
    };
  },

  async setup({ options }) {
    // main objects
    this.gizmo = new Gizmo({ options: { addTo: options.addTo } });
    this.sky = new Sky({ options: { addTo: options.addTo } });
    this.floor = new Floor({ options: { addTo: options.addTo } });
    this.box = new Box({
      options: { addTo: options.addTo },
      props: {
        dissolveCenter: (props) => this.gizmo.props.position,
        dissolveRadius: (props) =>
          map(this.gizmo.props.radius, 0, 1, 0.1, 1.75),
      },
    });

    // lights
    this.ambientLight = new THREE.AmbientLight(0x606575, 0.2);
    this.light = new THREE.DirectionalLight(0xffffff, 1.5);
    this.light.angle = Math.PI / 5;
    this.light.penumbra = 0.5;
    this.light.position.set(0.4, 0.3, -0.2);
    this.light.castShadow = true;
    this.light.shadow.camera.near = 0.01;
    this.light.shadow.camera.far = 5;
    this.light.shadow.mapSize.width = 256 * 4;
    this.light.shadow.mapSize.height = 256 * 4;
    this.light.shadow.bias = -0.002;
    this.light.shadow.radius = 3;

    if (options.addTo) {
      options.addTo.add(this.ambientLight);
      options.addTo.add(this.light);
    }

    this.props.active = true;
  },
});
