import * as THREE from 'three';
import IMOG from '~/lib/imog';
import { getGPUTier } from 'detect-gpu';
import useWindowSize from '~/lib/imog/use/windowSize';

import OrbitCamera from '~/component/Cameras/OrbitCamera';
import BasicCamera from '~/component/Cameras/BasicCamera';
import KeyboardCamera from '~/component/Cameras/KeyboardCamera';

import PostMaterial from './material';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from './UnrealBloomPass';

const quadGeometry = new THREE.PlaneBufferGeometry(1, 1);

export default IMOG.Component('Renderer', {
  props() {
    return {
      windowSize: useWindowSize(),
      pr: 1.5,
      size: (props) => ({
        width: props.windowSize.width,
        height: props.windowSize.height,
        pr: props.pr,
      }),
      cameraType: 'orbit',
      bloomActive: false,
      bloomStrength: 2,
      bloomThreshold: 0.78,
      bloomRadius: 0.7,
    };
  },

  setup() {
    this.renderer = new THREE.WebGL1Renderer({ antialiased: true });
    this.renderer.domElement.className = 'main-canvas';
    this.renderer.autoClear = false;
    this.renderer.setClearColor(0x111111);
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    // this.renderer.toneMappingExposure = 0.5;
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFShadowMap;

    document.body.appendChild(this.renderer.domElement);
    IMOG.inject('renderer', this.renderer);

    // world
    this.worldScene = new THREE.Scene();
    this.orbitCamera = new OrbitCamera();
    this.orbitCamera.init();
    this.worldCamera = this.orbitCamera.camera;
    this.orbitCamera.init();
    this.worldCamera = this.orbitCamera.camera;
    this.basicCamera = new BasicCamera({
      options: { addTo: this.worldScene },
      props: {
        active: (props) =>
          this.props.cameraType === 'basic' ||
          this.props.cameraType === 'orbit',
        helpers: (props) => this.props.cameraType === 'orbit',
      },
    });
    this.keyboardCamera = new KeyboardCamera({
      options: { addTo: this.worldScene },
      props: {
        active: (props) => this.props.cameraType === 'keyboard',
      },
    });

    this.worldTarget = new THREE.WebGLRenderTarget(1, 1, {
      format: THREE.RGBFormat,
    });
    this.worldTarget.texture.encoding = THREE.sRGBEncoding;
    this.worldTarget.texture.generateMipmaps = false;

    // screen
    this.postMaterial = new PostMaterial({
      options: {
        worldTarget: this.worldTarget.texture,
      },
    });
    this.screenScene = new THREE.Scene();
    this.screenQuad = new THREE.Mesh(quadGeometry, this.postMaterial.material);
    this.screenScene.add(this.screenQuad);
    this.screenCamera = new THREE.OrthographicCamera();
    this.screenCamera.position.z = 100;

    // effect composer
    this.renderScene = new RenderPass(this.screenScene, this.screenCamera);

    this.bloomPass = new UnrealBloomPass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      1.5,
      0.4,
      0.85
    );
    this.bloomPass.threshold = this.props.bloomThreshold;
    this.bloomPass.strength = this.props.bloomStrength;
    this.bloomPass.radius = this.props.bloomRadius;

    this.composer = new EffectComposer(this.$renderer);
    this.composer.addPass(this.renderScene);
    this.composer.addPass(this.bloomPass);
    // this.composer.addPass(this.LinearTosRGBPass);

    if (this.$gui) {
      const fCameras = this.$gui.addFolder({
        title: 'Cameras',
        expanded: false,
      });
      fCameras
        .addButton({ title: 'OrbitCamera' })
        .on('click', () => (this.props.cameraType = 'orbit'));
      fCameras
        .addButton({ title: 'BasicCamera' })
        .on('click', () => (this.props.cameraType = 'basic'));
      fCameras
        .addButton({ title: 'KeyboardCamera' })
        .on('click', () => (this.props.cameraType = 'keyboard'));
      const fGrading = this.$gui.addFolder({
        title: 'Grading',
        expanded: false,
      });
      fGrading
        .addInput(this.renderer, 'toneMapping', {
          options: {
            NoToneMapping: THREE.NoToneMapping,
            LinearToneMapping: THREE.LinearToneMapping,
            ReinhardToneMapping: THREE.ReinhardToneMapping,
            CineonToneMapping: THREE.CineonToneMapping,
            ACESFilmicToneMapping: THREE.ACESFilmicToneMapping,
          },
        })
        .on('change', (ev) => {
          this.worldScene.traverse((obj) => {
            if (obj.material) obj.material.needsUpdate = true;
          });
        });
      fGrading.addInput(this.renderer, 'toneMappingExposure', {
        min: 0,
        max: 5,
      });
      const fBloom = this.$gui.addFolder({
        title: 'Bloom',
        expanded: false,
      });
      fBloom.addInput(this.props, 'bloomActive');
      fBloom.addInput(this.props, 'bloomStrength');
      fBloom.addInput(this.props, 'bloomThreshold');
      fBloom.addInput(this.props, 'bloomRadius');
    }
    setTimeout(() => (this.props.bloomActive = true), 0);

    (async () => {
      const gpuTier = await getGPUTier({
        glContext: this.renderer.getContext(),
        desktopTiers: [0, 15, 30, 50],
      });
      this.props.pr = gpuTier.tier > 2 ? 2.5 : 1.5;
    })();
  },

  hooks: {
    'set:size'({ width, height, pr }) {
      // renderer
      this.renderer.setSize(width, height);
      this.renderer.setPixelRatio(pr);

      // world
      this.worldTarget.setSize(width * pr, height * pr);
      this.worldCamera.aspect = width / height;
      this.worldCamera.updateProjectionMatrix();

      this.composer.setSize(width * pr, height * pr);

      //screen
      this.screenCamera.left = (-width * pr) / 2;
      this.screenCamera.right = (width * pr) / 2;
      this.screenCamera.top = (height * pr) / 2;
      this.screenCamera.bottom = (-height * pr) / 2;
      this.screenCamera.updateProjectionMatrix();
      this.screenQuad.scale.set(width * pr, height * pr, 1);
    },

    'set:cameraType'(type) {
      if (type === 'orbit') {
        this.worldCamera = this.orbitCamera.camera;
        this.orbitCamera.controls.reset();
      }
      if (type === 'basic') {
        this.worldCamera = this.basicCamera.camera;
        this.orbitCamera.controls.saveState();
        this.basicCamera.props.wheelActive = true;
      }

      if (type === 'keyboard') {
        this.worldCamera = this.keyboardCamera.camera;
      }
    },
    'set:bloomStrength'(v) {
      this.bloomPass.strength = v;
    },
    'set:bloomThreshold'(v) {
      this.bloomPass.threshold = v;
    },
    'set:bloomRadius'(v) {
      this.bloomPass.radius = v;
    },
  },

  methods: {
    render(ms) {
      if (!this.props.bloomActive) {
        this.renderer.setRenderTarget(null);
        this.renderer.clear();
        this.renderer.render(this.worldScene, this.worldCamera);
      } else {
        // this.renderer.render(this.worldScene, this.worldCamera);
        this.renderer.setRenderTarget(this.worldTarget);
        this.renderer.clear();
        this.renderer.render(this.worldScene, this.worldCamera);
        this.renderer.setRenderTarget(null);
        this.renderer.clear();
        this.renderer.render(this.screenScene, this.screenCamera);
        this.composer.render();
      }
    },
  },
});
