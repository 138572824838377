import * as THREE from 'three';
import IMOG from '~/lib/imog';
import { pnoise } from '~/lib/glsl/noise';

const loader = new THREE.TextureLoader();

export default class BoxMeshStandardMaterial extends THREE.MeshDepthMaterial {
  constructor(options) {
    super({ ...options });

    this.uniforms = {
      uDissolveC: { value: new THREE.Vector3() },
      uDissolveR: { value: 0.6 },
      uDissolveF: { value: 0.1 },
      uTime: { value: 0 },
      ...THREE.ShaderLib.depth.uniforms,
    };

    this.type = 'BoxMeshDepthMaterial';

    this.vertexShader = `
    uniform vec3 uDissolveC;
    uniform float uDissolveR;
    uniform float uDissolveF;
    uniform float uTime;
    varying float vGrad;


    #include <common>
    #include <uv_pars_vertex>
    #include <displacementmap_pars_vertex>
    #include <morphtarget_pars_vertex>
    #include <skinning_pars_vertex>
    #include <logdepthbuf_pars_vertex>
    #include <clipping_planes_pars_vertex>
    // This is used for computing an equivalent of gl_FragCoord.z that is as high precision as possible.
    // Some platforms compute gl_FragCoord at a lower precision which makes the manually computed value better for
    // depth-based postprocessing effects. Reproduced on iPad with A10 processor / iPadOS 13.3.1.
    varying vec2 vHighPrecisionZW;

    ${pnoise}


    void main() {
    	#include <uv_vertex>
    	#include <skinbase_vertex>
    	#ifdef USE_DISPLACEMENTMAP
    		#include <beginnormal_vertex>
    		#include <morphnormal_vertex>
    		#include <skinnormal_vertex>
    	#endif
    	#include <begin_vertex>
    	#include <morphtarget_vertex>
    	#include <skinning_vertex>
    	#include <displacementmap_vertex>
    	#include <project_vertex>
    	#include <logdepthbuf_vertex>
    	#include <clipping_planes_vertex>
    	vHighPrecisionZW = gl_Position.zw;

      vec3 vvWorldPosition = (modelMatrix * vec4(transformed, 1.0)).xyz;

      vec3 n = vec3(
        pnoise((vvWorldPosition + uTime) * 8.0, vec3(3.0)),
        pnoise((vvWorldPosition + 0.32 - uTime) * 8.0, vec3(3.0)),
        pnoise((vvWorldPosition + 0.746 + uTime) * 8.0, vec3(3.0))
      );

      float fallof = smoothstep(uDissolveR + uDissolveF, uDissolveR, vGrad);
      vvWorldPosition += 0.05 * n * fallof;

      vGrad = length(vvWorldPosition - uDissolveC);

    }
    `;
    this.fragmentShader = `
    uniform float uDissolveR;
    uniform float uDissolveF;
    varying float vGrad;


    #if DEPTH_PACKING == 3200
    	uniform float opacity;
    #endif
    #include <common>
    #include <packing>
    #include <uv_pars_fragment>
    #include <map_pars_fragment>
    #include <alphamap_pars_fragment>
    #include <alphatest_pars_fragment>
    #include <logdepthbuf_pars_fragment>
    #include <clipping_planes_pars_fragment>
    varying vec2 vHighPrecisionZW;
    void main() {
    	#include <clipping_planes_fragment>
    	vec4 diffuseColor = vec4( 1.0 );
    	#if DEPTH_PACKING == 3200
    		diffuseColor.a = opacity;
    	#endif
    	#include <map_fragment>
    	#include <alphamap_fragment>
    	#include <alphatest_fragment>
    	#include <logdepthbuf_fragment>
    	// Higher precision equivalent of gl_FragCoord.z. This assumes depthRange has been left to its default values.
    	float fragCoordZ = 0.5 * vHighPrecisionZW[0] / vHighPrecisionZW[1] + 0.5;
    	#if DEPTH_PACKING == 3200
    		gl_FragColor = vec4( vec3( 1.0 - fragCoordZ ), opacity );
    	#elif DEPTH_PACKING == 3201
    		gl_FragColor = packDepthToRGBA( fragCoordZ );
    	#endif
      float a = step(uDissolveR, vGrad);
      if (a < 0.5) discard;
    }    `;
  }
}
