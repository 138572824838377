import * as THREE from 'three';
import IMOG from '~/lib/imog';

export default IMOG.Component('RendererMaterial', {
  options: {
    worldTarget: null,
  },

  setup({ options }) {
    this.material = new THREE.ShaderMaterial({
      vertexShader: /*glsl*/ `
        varying vec2 vUv;

        void main() {
            vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
            gl_Position = projectionMatrix * mvPosition;
            vUv = uv;
        }`,

      fragmentShader: /*glsl*/ `
        varying vec2 vUv;
        uniform sampler2D tWorld;

        void main() {
            vec4 worldColor = texture2D(tWorld, vUv);
            // gl_FragColor = LinearTosRGB(worldColor);
            gl_FragColor = worldColor;
        }`,
      uniforms: {
        tWorld: { value: options.worldTarget },
      },
    });
  },
});
